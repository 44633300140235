import { Address } from 'viem'

export type OpRewardsUser = {
  address: Address
  startTimestamp: number
  endTimestamp: number
  fees: number
  feesRank: number
  volume: number
  trades: number
  multiplier: number
  opRewards: number
  points: number
}

export type OpRewardsStats = {
  startTimestamp: number
  endTimestamp: number
  totalFees: number
  totalTrades: number
  totalVolume: number
  totalTraders: number
  lastUpdatedTimestamp: number
}

export type OpRewardsConfig = {
  startTimestamp: number
  endTimestamp: number
  totalOpRewards: number
}

export const OP_REWARDS_ROUNDS: OpRewardsConfig[] = [
  {
    startTimestamp: 1726617600000,
    endTimestamp: 1727222400000,
    totalOpRewards: 10_000,
  },
  {
    startTimestamp: 1727222400000,
    endTimestamp: 1727827200000,
    totalOpRewards: 10_000,
  },
]
