import useSWR from 'swr'

import { OpRewardsStats, OpRewardsUser } from '../constants/opRewards'
import useAuth from './useAuth'

const fetcher = async (): Promise<{
  user: OpRewardsUser | null | undefined
  stats: OpRewardsStats
}> => {
  const response = await fetch('/api/op-rewards', { cache: 'no-store' })
  return await response.json()
}

export default function useOpRewards() {
  const { user } = useAuth()
  return useSWR(['OpRewards', user?.address], fetcher)
}
